import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogActions, Chip, Box, Divider } from '@mui/material';
import * as flags from 'country-flag-icons/react/3x2';

const placeholderImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';

const languageOptions = [
  { value: 'ar', label: 'Arabic', flagCode: 'SA' },
  { value: 'bn', label: 'Bengali', flagCode: 'BD' },
  { value: 'bg', label: 'Bulgarian', flagCode: 'BG' },
  { value: 'zh', label: 'Chinese', flagCode: 'CN' },
  { value: 'hr', label: 'Croatian', flagCode: 'HR' },
  { value: 'cs', label: 'Czech', flagCode: 'CZ' },
  { value: 'da', label: 'Danish', flagCode: 'DK' },
  { value: 'nl', label: 'Dutch', flagCode: 'NL' },
  { value: 'en', label: 'English', flagCode: 'GB' },
  { value: 'et', label: 'Estonian', flagCode: 'EE' },
  { value: 'fil', label: 'Filipino', flagCode: 'PH' },
  { value: 'fi', label: 'Finnish', flagCode: 'FI' },
  { value: 'fr', label: 'French', flagCode: 'FR' },
  { value: 'de', label: 'German', flagCode: 'DE' },
  { value: 'el', label: 'Greek', flagCode: 'GR' },
  { value: 'he', label: 'Hebrew', flagCode: 'IL' },
  { value: 'hi', label: 'Hindi', flagCode: 'IN' },
  { value: 'hu', label: 'Hungarian', flagCode: 'HU' },
  { value: 'id', label: 'Indonesian', flagCode: 'ID' },
  { value: 'it', label: 'Italian', flagCode: 'IT' },
  { value: 'ja', label: 'Japanese', flagCode: 'JP' },
  { value: 'ko', label: 'Korean', flagCode: 'KR' },
  { value: 'lv', label: 'Latvian', flagCode: 'LV' },
  { value: 'lt', label: 'Lithuanian', flagCode: 'LT' },
  { value: 'ms', label: 'Malay', flagCode: 'MY' },
  { value: 'no', label: 'Norwegian', flagCode: 'NO' },
  { value: 'pl', label: 'Polish', flagCode: 'PL' },
  { value: 'pt', label: 'Portuguese', flagCode: 'PT' },
  { value: 'ro', label: 'Romanian', flagCode: 'RO' },
  { value: 'ru', label: 'Russian', flagCode: 'RU' },
  { value: 'sr', label: 'Serbian', flagCode: 'RS' },
  { value: 'sk', label: 'Slovak', flagCode: 'SK' },
  { value: 'sl', label: 'Slovenian', flagCode: 'SI' },
  { value: 'es', label: 'Spanish', flagCode: 'ES' },
  { value: 'sv', label: 'Swedish', flagCode: 'SE' },
  { value: 'th', label: 'Thai', flagCode: 'TH' },
  { value: 'tr', label: 'Turkish', flagCode: 'TR' },
  { value: 'uk', label: 'Ukrainian', flagCode: 'UA' },
  { value: 'vi', label: 'Vietnamese', flagCode: 'VN' },
].sort((a, b) => a.label.localeCompare(b.label));

function SavedStories() {
  const [stories, setStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const savedStories = JSON.parse(localStorage.getItem('savedStories') || '[]');
    setStories(savedStories);
  }, []);

  const handleDeleteStory = (id) => {
    const updatedStories = stories.filter(story => story.id !== id);
    setStories(updatedStories);
    localStorage.setItem('savedStories', JSON.stringify(updatedStories));
  };

  const handleViewStory = (story) => {
    setSelectedStory(story);
    setOpenDialog(true);
  };

  const getLanguageFlag = (languageLabel) => {
    const language = languageOptions.find(lang => lang.label === languageLabel);
    return language ? flags[language.flagCode] : null;
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Saved Stories
      </Typography>
      <Grid container spacing={3}>
        {stories.map((story) => (
          <Grid item xs={12} sm={6} md={4} key={story.id}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={story.imageUrl || placeholderImage}
                alt={story.title}
                sx={{
                  objectFit: story.imageUrl ? 'cover' : 'contain',
                  bgcolor: story.imageUrl ? 'inherit' : 'grey.300'
                }}
              />
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  {story.title}
                </Typography>
                <Box sx={{ 
                  display: 'inline-flex', 
                  alignItems: 'center',
                  lineHeight: 1, // Set line-height to 1 for better control
                }}>
                  {story.language && getLanguageFlag(story.language) && (
                    <Box 
                      component="span" 
                      sx={{ 
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginRight: '8px',
                        transform: 'translateY(-7px)', // Adjust this value as needed

                      }}
                    >
                      {React.createElement(getLanguageFlag(story.language), { 
                        style: { 
                          width: '16px',
                          height: '12px',
                        } 
                      })}
                    </Box>
                  )}
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    component="span" 
                    sx={{ 
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    {new Date(story.date).toLocaleDateString()}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => handleViewStory(story)}>View</Button>
                <Button size="small" onClick={() => handleDeleteStory(story.id)}>Delete</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>{selectedStory?.title}</DialogTitle>
        <DialogContent dividers>
          {selectedStory?.imageUrl && (
            <img 
              src={selectedStory.imageUrl} 
              alt={selectedStory.title} 
              style={{ maxWidth: '100%', height: 'auto', marginBottom: '1rem' }} 
            />
          )}
          <Box sx={{ mb: 2 }}>
            {selectedStory?.options && (
              <>
                <Chip label={`${selectedStory.options.length} minutes`} sx={{ mr: 1, mb: 1 }} />
                <Chip label={`Age: ${selectedStory.options.age}`} sx={{ mr: 1, mb: 1 }} />
                {selectedStory.options.moralities && selectedStory.options.moralities.map((moral, index) => (
                  <Chip key={index} label={moral} sx={{ mr: 1, mb: 1 }} />
                ))}
              </>
            )}
          </Box>
          {selectedStory?.content.split('\n\n').map((paragraph, index) => (
            <Typography key={index} paragraph>
              {paragraph}
            </Typography>
          ))}
          {selectedStory?.options?.characters && selectedStory.options.characters.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>Characters:</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {selectedStory.options.characters.map((char, index) => (
                  <Chip key={index} label={typeof char === 'string' ? char : char.name} />
                ))}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default SavedStories;