import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { 
  ThemeProvider, CssBaseline, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, 
  AppBar, Toolbar, Typography, InputBase, IconButton, useMediaQuery, ListItemButton,
  Avatar, Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Home, Diversity3, AutoStories, AddCircle, Notifications, ExitToApp } from '@mui/icons-material';
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import LoginScreen from './components/LoginScreen';
import StartPage from './pages/StartPage';
import GenerateStory from './pages/GenerateStory';
import SavedStories from './pages/SavedStories';
import SavedCharacters from './pages/SavedCharacters';
import Profile from './components/Profile';
import theme from './theme';

const drawerWidth = 240;

const menuItems = [
  { text: 'Home', icon: <Home />, path: '/' },
  { text: 'Generate Story', icon: <AddCircle />, path: '/generate' },
  { text: 'My Stories', icon: <AutoStories />, path: '/saved-stories' },
  { text: 'My Characters', icon: <Diversity3 />, path: '/saved-characters' },
];

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsLoggedIn(!!currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      setIsLoggedIn(false);
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Toolbar />
      <List>
        {menuItems.map((item) => (
          <ListItem 
            key={item.text} 
            disablePadding 
            component={Link} 
            to={item.path} 
            onClick={isMobile ? handleDrawerToggle : undefined}
            sx={{ color: 'text.primary', textDecoration: 'none' }}
          >
            <ListItemButton>
              <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.text} 
                primaryTypographyProps={{
                  sx: { 
                    lineHeight: '24px',
                    my: 'auto'
                  }
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <List>
        <ListItem 
          disablePadding 
          sx={{ color: 'text.primary', textDecoration: 'none' }}
        >
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon sx={{ color: 'inherit', minWidth: 40 }}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText 
              primary="Log out" 
              primaryTypographyProps={{
                sx: { 
                  lineHeight: '24px',
                  my: 'auto'
                }
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LoginScreen />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex' }}>
          <AppBar 
            position="fixed" 
            sx={{ 
              zIndex: (theme) => theme.zIndex.drawer + 1,
              borderRadius: 0
            }}
          >
            <Toolbar>
              {isMobile && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                Fairytel
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton color="inherit">
                  <Notifications />
                </IconButton>
                <Button 
                  color="inherit" 
                  component={Link} 
                  to="/profile"
                  sx={{ textTransform: 'none' }}
                >
                  <Avatar 
                    sx={{ width: 32, height: 32, mr: 1 }}
                    src={user?.photoURL || ''}
                  >
                    {user?.displayName ? user.displayName[0].toUpperCase() : 'U'}
                  </Avatar>
                  Profile
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar />
            <Routes>
              <Route path="/" element={<StartPage />} />
              <Route path="/generate" element={<GenerateStory />} />
              <Route path="/saved-stories" element={<SavedStories />} />
              <Route path="/saved-characters" element={<SavedCharacters />} />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;