import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, TextField, Button, Avatar, Box, Snackbar } from '@mui/material';
import { auth } from '../firebase';
import { updateProfile, sendPasswordResetEmail } from 'firebase/auth';

function Profile() {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser(currentUser);
      setDisplayName(currentUser.displayName || '');
      setEmail(currentUser.email || '');
    }
  }, []);

  const handleSave = async () => {
    try {
      await updateProfile(auth.currentUser, { displayName });
      setIsEditing(false);
      // Refresh user data
      setUser(auth.currentUser);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, user.email);
      setSnackbarMessage('Password reset email sent. Check your inbox.');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setSnackbarMessage('Failed to send password reset email. Try again later.');
      setSnackbarOpen(true);
    }
  };

  if (!user) return <Typography>Loading...</Typography>;

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
          <Avatar
            src={user.photoURL || ''}
            sx={{ width: 100, height: 100, mb: 2 }}
          >
            {user.displayName ? user.displayName[0].toUpperCase() : 'U'}
          </Avatar>
          <Typography variant="h4" gutterBottom>
            {user.displayName || 'User Profile'}
          </Typography>
        </Box>
        <TextField
          fullWidth
          label="Display Name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          disabled={!isEditing}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Email"
          value={email}
          disabled
          margin="normal"
        />
        {isEditing ? (
          <Button onClick={handleSave} variant="contained" sx={{ mt: 2 }}>
            Save Changes
          </Button>
        ) : (
          <Button onClick={() => setIsEditing(true)} variant="contained" sx={{ mt: 2 }}>
            Edit Profile
          </Button>
        )}
        <Button onClick={handlePasswordReset} variant="outlined" sx={{ mt: 2, ml: 2 }}>
          Reset Password
        </Button>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
}

export default Profile;