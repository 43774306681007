import React from 'react';
import { Grid, Paper, Typography, Box, Card, CardContent, CardMedia, Button } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const recentActivity = [
  { title: 'NEW STORIES', value: 741 },
  { title: 'SAVED STORIES', value: 123 },
  { title: 'CHARACTERS', value: 12 },
  { title: 'MESSAGES', value: 1 },
  { title: 'GROUPS', value: 4 },
];

const salesData = [
  { name: 'Confirmed', value: 400 },
  { name: 'Packed', value: 300 },
  { name: 'Refunded', value: 100 },
  { name: 'Shipped', value: 200 },
];

const topCategories = [
  { name: 'Fantasy', icon: '🧙‍♂️' },
  { name: 'Adventure', icon: '🏔️' },
  { name: 'Fairy Tale', icon: '🧚' },
  { name: 'Mystery', icon: '🕵️' },
  { name: 'Science Fiction', icon: '🚀' },
  { name: 'Historical', icon: '📜' },
];

function StartPage() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" gutterBottom>
        Recent activity
      </Typography>
      <Grid container spacing={3}>
        {recentActivity.map((item) => (
          <Grid item xs={12} sm={6} md={2.4} key={item.title}>
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
              <Typography variant="h4">{item.value}</Typography>
              <Typography variant="subtitle1">{item.title}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Story Generation Activity
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={salesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Top Story Categories
            </Typography>
            <Grid container spacing={1}>
              {topCategories.map((category) => (
                <Grid item xs={4} key={category.name}>
                  <Card>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="h4">{category.icon}</Typography>
                      <Typography variant="body2">{category.name}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Recent Stories
            </Typography>
            {/* Add a list or grid of recent stories here */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Popular Characters
            </Typography>
            {/* Add a list or grid of popular characters here */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StartPage;