import React, { useState, useEffect } from 'react';
import { 
  Container, Slider, TextField, MenuItem, Checkbox, Button, Typography, Box, Chip, 
  Paper, ThemeProvider, CssBaseline, InputLabel, FormControl, IconButton,
  CircularProgress, InputAdornment, Snackbar, Autocomplete, useTheme, useMediaQuery
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, AutoStories as AutoStoriesIcon } from '@mui/icons-material';
import axios from 'axios';
import * as flags from 'country-flag-icons/react/3x2';
import theme from '../theme';

const moralityOptions = [
  "Kindness", "Honesty", "Courage", "Friendship", "Sharing",
  "Responsibility", "Patience", "Teamwork", "Fairness", "Growth"
];

const languageOptions = [
  { value: 'ar', label: 'Arabic', Flag: flags.SA },
  { value: 'bn', label: 'Bengali', Flag: flags.BD },
  { value: 'bg', label: 'Bulgarian', Flag: flags.BG },
  { value: 'zh', label: 'Chinese', Flag: flags.CN },
  { value: 'hr', label: 'Croatian', Flag: flags.HR },
  { value: 'cs', label: 'Czech', Flag: flags.CZ },
  { value: 'da', label: 'Danish', Flag: flags.DK },
  { value: 'nl', label: 'Dutch', Flag: flags.NL },
  { value: 'en', label: 'English', Flag: flags.GB },
  { value: 'et', label: 'Estonian', Flag: flags.EE },
  { value: 'fil', label: 'Filipino', Flag: flags.PH },
  { value: 'fi', label: 'Finnish', Flag: flags.FI },
  { value: 'fr', label: 'French', Flag: flags.FR },
  { value: 'de', label: 'German', Flag: flags.DE },
  { value: 'el', label: 'Greek', Flag: flags.GR },
  { value: 'he', label: 'Hebrew', Flag: flags.IL },
  { value: 'hi', label: 'Hindi', Flag: flags.IN },
  { value: 'hu', label: 'Hungarian', Flag: flags.HU },
  { value: 'id', label: 'Indonesian', Flag: flags.ID },
  { value: 'it', label: 'Italian', Flag: flags.IT },
  { value: 'ja', label: 'Japanese', Flag: flags.JP },
  { value: 'ko', label: 'Korean', Flag: flags.KR },
  { value: 'lv', label: 'Latvian', Flag: flags.LV },
  { value: 'lt', label: 'Lithuanian', Flag: flags.LT },
  { value: 'ms', label: 'Malay', Flag: flags.MY },
  { value: 'no', label: 'Norwegian', Flag: flags.NO },
  { value: 'pl', label: 'Polish', Flag: flags.PL },
  { value: 'pt', label: 'Portuguese', Flag: flags.PT },
  { value: 'ro', label: 'Romanian', Flag: flags.RO },
  { value: 'ru', label: 'Russian', Flag: flags.RU },
  { value: 'sr', label: 'Serbian', Flag: flags.RS },
  { value: 'sk', label: 'Slovak', Flag: flags.SK },
  { value: 'sl', label: 'Slovenian', Flag: flags.SI },
  { value: 'es', label: 'Spanish', Flag: flags.ES },
  { value: 'sv', label: 'Swedish', Flag: flags.SE },
  { value: 'th', label: 'Thai', Flag: flags.TH },
  { value: 'tr', label: 'Turkish', Flag: flags.TR },
  { value: 'uk', label: 'Ukrainian', Flag: flags.UA },
  { value: 'vi', label: 'Vietnamese', Flag: flags.VN },
].sort((a, b) => a.label.localeCompare(b.label));

function GenerateStory() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [length, setLength] = useState(5);
  const [age, setAge] = useState(5);
  const [topic, setTopic] = useState('');
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [newCharacter, setNewCharacter] = useState('');
  const [generateImage, setGenerateImage] = useState(false);
  const [story, setStory] = useState('');
  const [selectedMoralities, setSelectedMoralities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [language, setLanguage] = useState(languageOptions.find(lang => lang.value === 'sv'));
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [savedCharacters, setSavedCharacters] = useState([]);
  const [storyTitle, setStoryTitle] = useState('');

  useEffect(() => {
    const characters = JSON.parse(localStorage.getItem('savedCharacters') || '[]');
    setSavedCharacters(characters);

    const synth = window.speechSynthesis;
    const updateVoices = () => {
      setVoices(synth.getVoices());
    };
    updateVoices();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = updateVoices;
    }
  }, []);

  const handleVoiceChange = (event) => {
    setSelectedVoice(voices.find(voice => voice.name === event.target.value));
  };

  const speak = (text) => {
    if (!selectedVoice) return;

    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = selectedVoice;

    utterance.onstart = () => setIsSpeaking(true);
    utterance.onend = () => setIsSpeaking(false);

    synth.speak(utterance);
  };

  const stopSpeaking = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsSpeaking(false);
  };

  const handleAddCharacter = () => {
    if (newCharacter.trim() !== '') {
      setSelectedCharacters([...selectedCharacters, { name: newCharacter.trim() }]);
      setNewCharacter('');
    }
  };

  const handleMoralityChange = (event) => {
    const value = event.target.value;
    setSelectedMoralities(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleGenerateStory = async () => {
    if (selectedMoralities.length > 2) {
      alert("Please select no more than 2 moral values before generating the story.");
      return;
    }
    
    setIsLoading(true);
    setStory('');
    setImageUrl(null);
    setStoryTitle('');
  
    try {
      const characterInfo = selectedCharacters.map(char => {
        if (char.age && char.gender && char.bio) {
          return `${char.name} (Age: ${char.age}, Gender: ${char.gender}) - ${char.bio}`;
        }
        return char.name;
      }).join('; ');
  
      const response = await axios.post('http://localhost:3001/api/generate-story', {
        length,
        age,
        topic,
        language: language.label,
        characters: characterInfo,
        moralities: selectedMoralities,
        generateImage,
      });
  
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      setStory(response.data.story);
      setImageUrl(response.data.imageUrl);
      setStoryTitle(response.data.title);
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setStory('An error occurred while generating the story. Please check the console for more details and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveStory = () => {
    if (!story) return;

    const savedStories = JSON.parse(localStorage.getItem('savedStories') || '[]');
    const newStory = {
      id: Date.now(),
      content: story,
      title: storyTitle || 'Untitled Story',
      date: new Date().toISOString(),
      language: language.label,
      imageUrl: imageUrl,
      options: {
        length,
        age,
        characters: selectedCharacters,
        moralities: selectedMoralities,
      }
    };
    savedStories.push(newStory);
    localStorage.setItem('savedStories', JSON.stringify(savedStories));
    setSaveSuccess(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" sx={{ py: isMobile ? 2 : 4 }}>
        <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, mb: isMobile ? 2 : 4 }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 2 : 3, fontSize: isMobile ? '1.5rem' : '2.125rem' }}>
            <AutoStoriesIcon sx={{ mr: isMobile ? 1 : 2, fontSize: isMobile ? 30 : 40 }} />
            Generate your unique story
          </Typography>
          
          <Box sx={{ mb: isMobile ? 2 : 3 }}>
            <Typography gutterBottom>Story Length: {length} minutes</Typography>
            <Slider
              value={length}
              onChange={(_, newValue) => setLength(newValue)}
              min={1}
              max={10}
              step={1}
              marks
              valueLabelDisplay="auto"
            />
          </Box>

          <Box sx={{ mb: isMobile ? 2 : 3 }}>
            <Typography gutterBottom>Target Age: {age} years old</Typography>
            <Slider
              value={age}
              onChange={(_, newValue) => setAge(newValue)}
              min={1}
              max={10}
              step={1}
              marks
              valueLabelDisplay="auto"
            />
          </Box>

          <TextField
            fullWidth
            label="Story Topic"
            variant="outlined"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            margin="normal"
            sx={{ mb: isMobile ? 2 : 3 }}
          />

          <TextField
            select
            fullWidth
            label="Language"
            value={language.value}
            onChange={(e) => setLanguage(languageOptions.find(lang => lang.value === e.target.value))}
            sx={{ mb: isMobile ? 2 : 3 }}
            SelectProps={{
              renderValue: (selected) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {language.Flag && <language.Flag style={{ marginRight: '8px', width: '24px' }} />}
                  {language.label}
                </div>
              ),
            }}
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <InputAdornment position="start">
                  <option.Flag style={{ width: '24px', marginRight: '8px' }} />
                </InputAdornment>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ mb: isMobile ? 2 : 3 }}>
            <Typography gutterBottom>Characters:</Typography>
            <Autocomplete
              multiple
              id="characters-select"
              options={savedCharacters}
              getOptionLabel={(option) => option.name}
              value={selectedCharacters}
              onChange={(event, newValue) => {
                setSelectedCharacters(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Characters"
                  placeholder="Characters"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
            <Box display="flex" alignItems="center" mt={1}>
              <TextField
                label="New Character"
                value={newCharacter}
                onChange={(e) => setNewCharacter(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ mr: 1 }}
              />
              <IconButton onClick={handleAddCharacter} color="primary">
                <AddIcon />
              </IconButton>
            </Box>
          </Box>

          <FormControl fullWidth sx={{ mb: isMobile ? 2 : 3 }}>
            <TextField
              select
              label="Moral Values (Optional: Select up to 2)"
              value={selectedMoralities}
              onChange={handleMoralityChange}
              error={selectedMoralities.length > 2}
              helperText={selectedMoralities.length > 2 ? "Please select no more than 2 moral values" : ""}
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                ),
              }}
            >
              {moralityOptions.map((morality) => (
                <MenuItem 
                  key={morality} 
                  value={morality}
                  disabled={selectedMoralities.length >= 2 && !selectedMoralities.includes(morality)}
                >
                  {morality}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <Box sx={{ mb: isMobile ? 2 : 3, display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={generateImage}
              onChange={(e) => setGenerateImage(e.target.checked)}
            />
            <Typography>Generate Image</Typography>
          </Box>

<Button 
            variant="contained" 
            color="primary" 
            onClick={handleGenerateStory} 
            fullWidth
            disabled={isLoading}
            sx={{ py: 1.5, fontSize: isMobile ? '1rem' : '1.1rem', mb: isMobile ? 2 : 4 }}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Generate Story'}
          </Button>
        </Paper>

        {story && (
          <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, mt: isMobile ? 2 : 4 }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: isMobile ? '1.25rem' : '1.5rem' }}>{storyTitle}</Typography>
            {imageUrl && (
              <Box sx={{ mb: isMobile ? 2 : 3, display: 'flex', justifyContent: 'center' }}>
                <img src={imageUrl} alt="Generated fairytale illustration" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
              </Box>
            )}
            <Box sx={{ mb: isMobile ? 1 : 2 }}>
              <TextField
                select
                label="Select Voice"
                value={selectedVoice ? selectedVoice.name : ''}
                onChange={handleVoiceChange}
                fullWidth
                sx={{ mb: 2 }}
              >
                {voices.map((voice) => (
                  <MenuItem key={voice.name} value={voice.name}>
                    {`${voice.name} (${voice.lang})`}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                onClick={() => speak(story)}
                disabled={!selectedVoice || isSpeaking}
                sx={{ mr: 1, mb: isMobile ? 1 : 0 }}
              >
                {isSpeaking ? 'Speaking...' : 'Read Aloud'}
              </Button>
              <Button
                variant="outlined"
                onClick={stopSpeaking}
                disabled={!isSpeaking}
                sx={{ mr: 1, mb: isMobile ? 1 : 0 }}
              >
                Stop
              </Button>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleSaveStory}
              >
                Save Story
              </Button>
            </Box>
            {story.split('\n\n').map((paragraph, index) => (
              <Typography key={index} paragraph>
                {paragraph}
              </Typography>
            ))}
          </Paper>
        )}

        <Snackbar
          open={saveSuccess}
          autoHideDuration={3000}
          onClose={() => setSaveSuccess(false)}
          message="Story saved successfully!"
        />
      </Container>
    </ThemeProvider>
  );
}

export default GenerateStory;