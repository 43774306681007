import React, { useState, useEffect } from 'react';
import { 
  Container, Typography, Grid, Card, CardContent, CardMedia, 
  Button, TextField, Dialog, DialogTitle, DialogContent, 
  DialogActions, Select, MenuItem, FormControl, InputLabel,
  IconButton, Box
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

function CharacterCard({ character, onEdit, onDelete }) {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        height="200"
        image={character.image || 'https://via.placeholder.com/200'}
        alt={character.name}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="h5" component="div">
          {character.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Age: {character.age}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Gender: {character.gender}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {character.bio}
        </Typography>
      </CardContent>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
        <IconButton onClick={() => onEdit(character)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(character.id)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );
}

function SavedCharacters() {
  const [characters, setCharacters] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingCharacter, setEditingCharacter] = useState(null);
  const [newCharacter, setNewCharacter] = useState({
    name: '',
    age: '',
    gender: '',
    bio: '',
    image: ''
  });

  useEffect(() => {
    const savedCharacters = JSON.parse(localStorage.getItem('savedCharacters') || '[]');
    setCharacters(savedCharacters);
  }, []);

  const handleOpenDialog = (character = null) => {
    if (character) {
      setEditingCharacter(character);
      setNewCharacter(character);
    } else {
      setEditingCharacter(null);
      setNewCharacter({
        name: '',
        age: '',
        gender: '',
        bio: '',
        image: ''
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingCharacter(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewCharacter({ ...newCharacter, [name]: value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewCharacter({ ...newCharacter, image: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveCharacter = () => {
    let updatedCharacters;
    if (editingCharacter) {
      updatedCharacters = characters.map(char => 
        char.id === editingCharacter.id ? { ...newCharacter, id: char.id } : char
      );
    } else {
      updatedCharacters = [...characters, { ...newCharacter, id: Date.now() }];
    }
    setCharacters(updatedCharacters);
    localStorage.setItem('savedCharacters', JSON.stringify(updatedCharacters));
    handleCloseDialog();
  };

  const handleDeleteCharacter = (id) => {
    const updatedCharacters = characters.filter(char => char.id !== id);
    setCharacters(updatedCharacters);
    localStorage.setItem('savedCharacters', JSON.stringify(updatedCharacters));
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Saved Characters
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog()} sx={{ mb: 3 }}>
        Create New Character
      </Button>

      <Grid container spacing={3}>
        {characters.map((character) => (
          <Grid item key={character.id} xs={12} sm={6} md={4}>
            <CharacterCard 
              character={character} 
              onEdit={() => handleOpenDialog(character)}
              onDelete={handleDeleteCharacter}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editingCharacter ? 'Edit Character' : 'Create New Character'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={newCharacter.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="age"
            label="Age"
            type="number"
            fullWidth
            value={newCharacter.age}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Gender</InputLabel>
            <Select
              name="gender"
              value={newCharacter.gender}
              onChange={handleInputChange}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="bio"
            label="Short Bio"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newCharacter.bio}
            onChange={handleInputChange}
          />
          <input
            accept="image/*"
            type="file"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
            id="character-image-upload"
          />
          <label htmlFor="character-image-upload">
            <Button variant="contained" component="span" sx={{ mt: 2 }}>
              Upload Image
            </Button>
          </label>
          {newCharacter.image && (
            <Box sx={{ mt: 2 }}>
              <img src={newCharacter.image} alt="Character" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveCharacter}>{editingCharacter ? 'Update' : 'Save'}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default SavedCharacters;