import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBJjDX6Kmw9WweiLVm5p2ltqEnFowCWLuk",
  authDomain: "fairytel-13b3c.firebaseapp.com",
  projectId: "fairytel-13b3c",
  storageBucket: "fairytel-13b3c.appspot.com",
  messagingSenderId: "810675744746",
  appId: "1:810675744746:web:b07c57457163ef22100c93",
  measurementId: "G-6GR6T0G212"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);